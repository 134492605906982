import { motion } from 'framer-motion'
import images from '../../constants/images'

import './About.scss'
const About = () => {
  return (
    <div id='about' className='app__about app__flex app__container'>
      <motion.div 
      whileInView={{ x: [-100, 0], opacity: [0, 1] }}
      transition={{ duration: 1, delay:.5}}
      className="about-left r-text">
        <h2 className='subHead-text'>About us</h2>
        <p >
          Salan company based in Erbil-IRAQ is a company focused on agriculture machinery and products sales in Kurdistan region of IRAQ. With a humble beginning in 2010 it has accomplished a highly coveted industrial 
          journey to become a reputed and recognized supplier in KRI. Company’s main products and machines supplied and sold are:
        </p>
        <ul >
          <li>Tractors</li>
          <li>Irrigation System</li>
          <li>Trucks</li>
          <li>Combine Harvester</li>
          <li>Farmland preparation machine</li>
          <li>Cultivator, rotary tiller, double plough, potato harvester, rice cutter farm equipment</li>
          <li>Animal feed</li>
          <li>Farm empowering and strengthens powders</li>
        </ul>
        <p>
          The company has been supplying its machinery and products to normal farmers, governments, private companies and have built a solid ground in the market for agriculture and farming machineries and products.</p>
      </motion.div>
      <motion.div 
      whileInView={{ x: [100, 0], opacity: [0, 1] }}
      transition={{ duration: 1}}
      className="about-right">
        <img src={images.about1} alt="tractor image" />
        
      </motion.div>
    </div>
  )
}

export default About