import './Home.scss'
import {motion} from 'framer-motion'
function Home() {
  return (
    <div id='home' className="app__home">
      <div className='app__container'>
        <motion.div 
        whileInView={{ y: [100, 0], opacity: [0, 1] }}
        transition={{ duration: 2}}
        className='home-text'>
          <h1 className='head-text'>
            <span>S</span>alan Company
          </h1>
          <p className='p-text'>
            For General Trading        
          </p>
          <a href="#about" className='p-text'>
            Learn More
          </a>
        </motion.div>
      </div>
    </div>
  )
}

export default Home