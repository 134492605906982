import './App.scss';
import { Navbar , Loader} from './Components';
import { About, Contact, Footer, Services, Work } from './Container';
import {lazy , Suspense} from 'react';

// const Home = lazy(()=>import('./Container/Home/Home'))
const Home = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import('./Container/Home/Home')), 2000);
  });
});
function App() {
  return (
    <div className="app">
      <Suspense fallback={<Loader/>}>
        <Navbar/>
        <Home/>
        <About/>
        <Services/>
        <Work/>
        <Contact/>
        <Footer/>
      </Suspense>
    </div>
  );
}

export default App;
