import './Contact.scss'
import {IoCallOutline, IoLocationOutline, IoMailOutline} from 'react-icons/io5'
import { motion } from 'framer-motion';
const Contact = () => {
  return (
    <div id='contact' className='app__contact app__container'>
        <h2 className='subHead-text'>Contact Us</h2>
        <div className="contact-info">
          <motion.div 
          whileInView={{ x: [-100, 0], opacity: [0, 1] }}
          transition={{ duration: 1}}
          className="contact-left contact-flex">
              <IoLocationOutline/>
              <h3 className='bold-text'>Address</h3>
              <p className='r-text'>Erbil, Bahrka Road</p>
          </motion.div>
          <div className="contact-right">
            <motion.div 
            whileInView={{ x: [100, 0], opacity: [0, 1] }}
            transition={{ duration: 1, delay:.5}}
            className="contact-email contact-flex">
              <IoMailOutline/>
              <h3 className='bold-text'>Email</h3>
              <a href="mailto:info@salan-erbil.com" className="r-text">info@salan-erbil.com</a>
            </motion.div>
            <motion.div 
            whileInView={{ x: [100, 0], opacity: [0, 1] }}
            transition={{ duration: 1, delay:.7}}
            className="contact-phone contact-flex">
              <IoCallOutline/>
              <h3 className='bold-text'>Phone</h3>
              <a href="tel:+964 750 456 2636" className="r-text">+964 750 456 2636</a>

            </motion.div>
          </div>
        </div>
    </div>
  )
}

export default Contact