import logo from '../assets/images/salan-logo.png';
import logoPortrait from '../assets/images/salan-logo-portrait.png';
import logoWhite from '../assets/images/salan-logo-white.png';
import about1 from '../assets/images/about-1.png';
import services1 from '../assets/images/services1.png';
import ocimsLogo from '../assets/images/ocims-logo.png';

export default {
  logo,
  logoPortrait,
  logoWhite,
  about1,
  services1,
  ocimsLogo,
};