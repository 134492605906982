import React from 'react'
import './Loader.scss'
const Loader = () => {
  return (
    <div className="loader">
        <div className="spinner"></div>
        <h1 className='head-text'><span>S</span>alan Company for General Trading LTD</h1>
    </div>
  )
}

export default Loader