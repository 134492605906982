import images from '../../constants/images'
import './Work.scss'
import {motion} from 'framer-motion'
const Work = () => {
  return (
    <div id='work' className='app__work'>
      <h2 className='subHead-text'>Work</h2>
      <motion.div 
      whileInView={{ y: [100, 0], opacity: [0, 1] }}
      transition={{ duration: 1}}
      className="company app__flex">
        <div className="company__shape app__flex">
          <img src={images.ocimsLogo} alt="Ocimis Company Logo" className="company__img"/>
        </div>
        <div className="company__text">
            <h3 className="bold-text">Ocmis Irrigazione Spa's Exclusive agent in Kurdistan Region and Iraq</h3>
            <p className='r-text'>
              Ocmis is the greatest global constructor of advanced systems for agricultural irrigation and green spaces. <br/>
              We design and produce irrigation machines and we are famous worldwide for our self-propelling irrigators known as “Rollers”. <br/>
              Ocmis is the first company in Italy to respond to agriculturalists’ real needs. Year after year, their efforts in terms of investments and strategic choices have resulted in a constantly growing number of our systems present worldwide.
            </p>
            <a href="https://www.ocmis-irrigazione.it/en" target='_blank' rel='noreferrer' className='company__website'>Visit Ocims Website</a>
          </div>
        
        </motion.div>
    </div>
  )
}

export default Work