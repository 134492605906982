import images from '../../constants/images'
import './Services.scss'
import {motion} from 'framer-motion';
const Services = () => {
  return (
    <div id='services' className="app__services">
        <h2 className='subHead-text'>Services</h2>
      <div  className='app__container'>
        <div className="services-container">

          <motion.div 
          whileInView={{ y: [100, 0], opacity: [0, 1] }}
          transition={{ duration: 1, delay:.5}}
          className="services-left r-text">
            <p className='r-text'>
              We at Salan company are going to have dedicated teams to support the Your products from sales to customer services and support. We will be having a clear roadmap and objectives for your products and plans to enlarge the business in three phases with the proper plan, resources, budget, and strategies.
            </p>
            <h3 className='bold-text'>Marketing Activities</h3>
            <p >
              Promoting your business, whether generating leads or traffic to your products and services, is one of the most important functions of any business. In this section of the plan, we provide the details of how we do marketing at Salan company:
            </p>
            <p > <span>Key Message:</span> The key message and motto that we want our customers to recognize us for and remember the first time they will hear, see, or think about us is to deliver best-in-class agricultural machineries, products, and services.</p>
            <p >
              <span>Marketing Activities:</span> We use below promotional options for our services recognition, campaigns, and announcements. 
            </p>
            <ul>
              <li>Digital marketing (social media, email marketing and website SEO)</li>
              <li>Media advertising (Television and Radio)</li>
              <li>Seminars or business conferences</li>
              <li>Website</li>
              <li>Joint advertising with other companies</li>
              <li>Word of mouth or fixed signage</li>
              <li>Traditional marketing</li>
              <li>Billboard</li>
            </ul>
          </motion.div>
          <motion.div 
          whileInView={{ x: [100, 0], opacity: [0, 1] }}
          transition={{ duration: 1,}}
          className="services-right">
            <img src={images.services1} alt="our services image" />
          </motion.div>


        </div>
      </div>
    </div>
  )
}

export default Services