import images from '../../constants/images'
import './Footer.scss'
import { IoArrowForward } from 'react-icons/io5'
import { motion } from 'framer-motion'
const Footer = () => {
  return (
    <div className='app__footer'>

      <div className="footer">
        <motion.div 
          whileInView={{ x: [-100, 0], opacity: [0, 1] }}
          transition={{ duration: 1}}
        className="logo">

        <img src={images.logoWhite} alt="salan company for general trading" />
       
        </motion.div>
        <motion.div 
          whileInView={{ y: [-100, 0], opacity: [0, 1] }}
          transition={{ duration: 1}}
        className="footer-links">
          <h3 className='bold-text'>Company</h3>
          <div className="footer-link">
            <IoArrowForward/>
            <a href="#home">Home</a>
          </div>
          <div className="footer-link">
            <IoArrowForward/>
            <a href="#about">About</a>
          </div>
          <div className="footer-link">
            <IoArrowForward/>
            <a href="#services">Services</a>
          </div>
          <div className="footer-link">
            <IoArrowForward/>
            <a href="#work">Work</a>
          </div>
          <div className="footer-link">
            <IoArrowForward/>
            <a href="#contact">Contact</a>
          </div>
        </motion.div>
        <motion.div 
        whileInView={{ y: [100, 0], opacity: [0, 1] }}
         transition={{ duration: 1}}
        className="footer-contact">
          <h3 className="bold-text">
            Contact Us
          </h3>
          <p>Erbil, Bahrka Road</p>
          <a href="mailto:info@salan-erbil.com" className="r-text">info@salan-erbil.com</a>
          <a href="tel:+964 750 456 2636" className="r-text">+964 750 456 2636</a>
        </motion.div>
      </div>
      <div className="hr"></div>
      <motion.div 
      whileInView={{ y: [-200, 0], opacity: [0, 1] }}
      transition={{ duration: 1, delay:0.5}}
      className="copyright-container">
        <div className="copyright-company">
          <p> &copy; 2022 Salan Co. for General Trading - All rights reserved </p>
        </div>
        <div className="copyright-developer">
          <p>Powered by <a href="https://salih-yaseen.netlify.app/" target='_blank'>Salih Yaseen</a></p>
        </div>
      </motion.div>
    </div>
  )
}

export default Footer